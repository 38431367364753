/**
 * Returns body for searching letterbox for BOTH postalcode and searchtext (GB only currently).
 */
export const getTextAndPostcodeSearchBody = (query: string, countryCode = 'GB') => ({
  address: {
    postalCode: query, // Use query as postcode, also provide as searchText. Results will favour results from postcode lookup.
    countryCode,
  },
  searchText: query,
});

/**
 * Returns body for searching letterbox for searchtext only (outside UK).
 */
export const getTextSearchBody = (query: string, countryCode = 'US') => ({
  address: {
    countryCode,
  },
  searchText: query,
});
