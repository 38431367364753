export enum Country {
  US = 'US',
  GB = 'GB',
}

export const VQ_LETTERBOX_URL = '/vq/addresses/lookup';

export enum SearchMode {
  SEARCH_BY_ADDRESS = 'SEARCH_BY_ADDRESS',
  SEARCH_FOR_UK_POSTCODE = 'SEARCH_FOR_UK_POSTCODE',
}

export const getSearchMode = (country: string) => {
  if (country === 'GB') return SearchMode.SEARCH_FOR_UK_POSTCODE;
  return SearchMode.SEARCH_BY_ADDRESS;
};

export const addressSuggestionsHintTexts = {
  [SearchMode.SEARCH_BY_ADDRESS]: 'Search or paste your customer address',
  [SearchMode.SEARCH_FOR_UK_POSTCODE]: 'Search or paste your customer postcode',
};
