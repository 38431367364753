import { ReactNode } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '@veeqo/ui';
import { useIsMobile } from 'utils/useIsMobile';
import { Footer } from './Footer';
import Navbar from './Navbar';
import Aside from './Aside';

interface LayoutProps {
  children: ReactNode;
  showLogo?: boolean;
  showLogin?: boolean;
  showSignup?: boolean;
  isEvenSidebar?: boolean;
  preventAnimations?: boolean;
  asideContent?: ReactNode;
}

const Wrapper = styled(motion.div)<{ ismobile: string }>`
  display: ${({ ismobile }) => (ismobile === 'mobile' ? 'block' : 'grid')};
`;

const Main = styled(motion.main)`
  min-height: 100vh;
  padding: 0 ${theme.sizes[9]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  & > div {
    width: 100%;
  }

  @media (min-width: 1650px) {
    & > div {
      width: 100%;
      flex-basis: 80%;
      justify-content: center;
    }
  }
`;

const Layout = ({
  children,
  showLogo = false,
  showLogin = false,
  showSignup = false,
  isEvenSidebar = false,
  preventAnimations = false,
  asideContent = <Aside />,
}: LayoutProps) => {
  const { isMobile } = useIsMobile();

  const variants = {
    half: { gridTemplateColumns: '50% 50%' },
    third: { gridTemplateColumns: '75% 25%' },
  };

  const getInitialGrid = () => {
    if (preventAnimations) return variants.third;
    return isEvenSidebar ? variants.third : variants.half;
  };

  const getAnimatedGrid = () => {
    if (preventAnimations) return variants.third;
    return isEvenSidebar ? variants.half : variants.third;
  };

  const getInitialOpacity = () => (preventAnimations ? 1 : 0);
  const getAnimatedOpacity = () => (preventAnimations ? 1 : 1);

  return (
    <Wrapper
      ismobile={isMobile ? 'mobile' : 'desktop'}
      initial={getInitialGrid()}
      animate={getAnimatedGrid()}
      transition={{ duration: 0.3, type: 'tween', ease: 'easeOut' }}
    >
      <Main
        initial={{ opacity: getInitialOpacity() }}
        animate={{ opacity: getAnimatedOpacity() }}
        transition={{ duration: 0.4 }}
      >
        <Navbar
          logo={showLogo}
          login={showLogin}
          signup={showSignup}
          preventAnimations={preventAnimations}
        />
        <Content>{children}</Content>
        <Footer />
      </Main>
      {!isMobile && asideContent}
    </Wrapper>
  );
};

export default Layout;
