/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Text, Stack, TextField } from '@veeqo/ui';
import formValidations from 'utils/validations';
import InputGroup from 'components/common/InputGroup';
import { submitLogin, LoginResponse } from 'utils/api';
import { loginTracking } from 'Marketing/loginTracking';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'context/Context';
import { reportUserDataMissing } from 'utils/adobeAnalytics/utils/utils';
import { trackFormInteraction } from 'utils/adobeAnalytics/trackFormInteraction';
import { useTrackForm } from 'utils/adobeAnalytics/hooks/useTrackForm';
import { Form } from '../styled';

type FormData = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const { actions } = useAppContext();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { trackFormUpdated } = useTrackForm('signin-form');

  const onSubmit = async (formData: FormData) => {
    setIsButtonLoading(true);
    const {
      redirect_to: redirectTo,
      error,
      isVerificationRequired,
      user_id,
      company_id,
      company_name,
      is_employee,
    }: LoginResponse = await submitLogin(formData);

    // set verification state
    actions.userNeedVerification(Boolean(isVerificationRequired));

    if (isVerificationRequired) {
      actions.setSignupData(formData);
      navigate('verify', { replace: true });
      return;
    }

    if (!redirectTo || error) {
      setIsButtonLoading(false);
      return setHasLoginFailed(true);
    }

    loginTracking(formData.email, is_employee);

    if (!!user_id && !!company_id && !!company_name) {
      trackFormInteraction({
        formName: 'signin-form',
        interactionType: 'submit',
        user: {
          id: user_id,
        },
        account: {
          companyID: company_id,
          companyName: company_name,
        },
      });
    } else {
      reportUserDataMissing({ user_id, company_id, company_name });
    }

    global.location.assign(redirectTo);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="signin-form">
      <Stack spacing={6}>
        <InputGroup>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                onBlur={trackFormUpdated('email')}
                value={value}
                aria-label="email"
                type="text" // avoid browser validation when starting typing
                label="Email"
                placeholder="youremail@mail.com"
                error={errors.email && errors.email.message}
                className="fs-exclude"
                autoComplete="username"
              />
            )}
            rules={formValidations.email}
          />
        </InputGroup>
        <InputGroup>
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                onBlur={trackFormUpdated('password')}
                value={value}
                aria-label="password"
                type="password"
                label="Password"
                placeholder="********"
                error={errors.password && errors.password.message}
                className="fs-exclude"
                autoComplete="current-password"
              />
            )}
            rules={{ required: 'Password is required' }}
          />
        </InputGroup>
        {hasLoginFailed && (
          // TODO: Adapt copy to error types
          <Text variant="errorSmall">
            Wrong credentials. Try again or click Forgot password to reset it.
          </Text>
        )}
        <Button
          type="submit"
          variant="primary"
          loading={isButtonLoading}
          disabled={isButtonLoading}
        >
          Login
        </Button>
      </Stack>
    </Form>
  );
};

export default LoginForm;
