import { AdobeEvents } from './constants';
import { PageViewPageData } from './types';
import {
  getAccountObject,
  getArticleObject,
  getCustomerStoryObject,
  getErrorObject,
  getGuideObject,
  getPageObject,
  getUserObject,
} from './utils/adobeObjectUtils';
import { checkIsSatelliteDefined } from './utils/checkIsSatelliteDefined';
import { reportSatelliteMissing } from './utils/utils';

export const trackPageView = () => {
  if (!checkIsSatelliteDefined()) return reportSatelliteMissing({ shouldSendToSentry: false });

  const eventData: PageViewPageData = {
    page: getPageObject(),
    // All below are defaulted values for their object, since this doesn't relate to unauthed user on signup app.
    user: getUserObject(),
    account: getAccountObject(),
    error: getErrorObject(),
    article: getArticleObject(),
    guide: getGuideObject(),
    customerStory: getCustomerStoryObject(),
  };

  _satellite.track(AdobeEvents.PAGE_VIEW, eventData);
};
