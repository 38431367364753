import styled from 'styled-components';
import { Text, theme } from '@veeqo/ui';
import links from 'utils/externalLinks';
import { handleTrackLinkClick } from 'utils/adobeAnalytics/trackLinkInteraction';

interface TermsConditionsProps {
  country?: string;
  waitingList?: boolean;
}

const TermsContainer = styled.div`
  margin-top: ${theme.sizes[6]};
  text-align: center;

  a {
    color: ${theme.text.hintText.color};
  }
`;

const TermsUS = () => (
  <>
    <span>By creating an account, you agree to the Veeqo </span>
    <a
      href={links.veeqoTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Terms of Service
    </a>
    <span>, Veeqo </span>
    <a
      href={links.veeqoPrivacy}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Privacy Notice
    </a>
    <span>, Amazon Shipping </span>
    <a
      href={links.amazonShippingTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Terms and Conditions
    </a>
    <span>, FedEx </span>
    <a
      href={links.fedexTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Terms of use
    </a>
    <span>, UPS </span>
    <a
      href={links.upsTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Terms and Conditions
    </a>
    <span>, USPS </span>
    <a
      href={links.uspsTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Postal Explorer terms
    </a>
    <span>, DHL </span>
    <a
      href={links.dhlTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      eCommerce Terms and Conditions
    </a>
    <span>, and OnTrac </span>
    <a
      href={links.onTracTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Terms and Conditions
    </a>
    .
  </>
);

const TermsGB = () => (
  <>
    <span>By creating an account, you agree to the Veeqo </span>
    <a
      href={links.veeqoTermsUK}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Terms and Conditions
    </a>
    <span> and Veeqo </span>
    <a
      href={links.veeqoPrivacy}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      Privacy Notice
    </a>
    .
  </>
);

const TermsWaitingList = () => (
  <>
    <span>By entering your information into this form you agree to Veeqo's </span>
    <a
      href={links.veeqoPrivacy}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      privacy policy
    </a>
    <span> and </span>
    <a
      href={links.veeqoTerms}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleTrackLinkClick('terms-conditions')}
    >
      terms and conditions
    </a>
    .
  </>
);

const TermsConditions = ({ country, waitingList = false }: TermsConditionsProps) => {
  const isGB = country === 'GB';

  const termsByCountry = isGB ? <TermsGB /> : <TermsUS />;

  return (
    <TermsContainer>
      <Text variant="hintText">{waitingList ? <TermsWaitingList /> : termsByCountry}</Text>
    </TermsContainer>
  );
};

export default TermsConditions;
