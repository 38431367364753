import * as Sentry from '@sentry/react';

export const MAX_RETRIES = 5; // Maximum number of retries
export const RETRY_DELAY = 300; // Delay between retries (in ms)

/**
 * A function which retries a passed function upto three times until it returns true.
 *
 * **Example**: Adobe Analytics is brought in late, but we need to track a page view event. The fn
 * returns false when it hasn't successfully sent the event.
 */
export const retry = async (fn: () => boolean, retryCount = 0): Promise<boolean> => {
  try {
    const result = fn();

    if (result === false && retryCount < MAX_RETRIES) {
      await new Promise((resolve) => {
        setTimeout(resolve, RETRY_DELAY * retryCount);
      });
      return await retry(fn, retryCount + 1);
    }

    if (retryCount >= MAX_RETRIES) {
      Sentry.captureMessage(`AA Not loaded after ${MAX_RETRIES} attempts`);
    }

    return result;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};
