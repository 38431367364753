import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { Button, Stack, TextField, Checkbox, Select } from '@veeqo/ui';
import formValidations from 'utils/validations';
import InputGroup from 'components/common/InputGroup';
import { mobileWaitingListSignupTracking } from 'Marketing/mobileSignupTracking';
import { useTrackForm } from 'utils/adobeAnalytics/hooks/useTrackForm';
import { MARKETING_OPT_IN_COPY } from '../../constants';
import { countryOptions } from 'components/Signup/CompanyDetailsForm';

export type MobileFormData = {
  firstName: string;
  lastName: string;
  email: string;
  marketingConsent?: boolean;
  country: string;
};

type WaitingFormProps = {
  onMobileSuccess: () => void;
};

const Form = styled.form`
  button {
    width: 100%;
  }
  .stack--full-width {
    width: 100%;
  }
`;

const MarketingCheck = styled.div`
  * {
    font-size: 12px;
  }
`;

const MobileWaitingListForm = ({ onMobileSuccess }: WaitingFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MobileFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      marketingConsent: false,
      country: 'US',
    },
  });

  const { trackFormUpdated } = useTrackForm('mobile-lead-form');

  const onSubmit = handleSubmit((data) => {
    mobileWaitingListSignupTracking(data);
    onMobileSuccess();
  });

  return (
    <Form onSubmit={onSubmit} id="mobile-lead-form">
      <Stack spacing={6}>
        <InputGroup>
          <Controller
            name="firstName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                onBlur={trackFormUpdated('firstName')}
                value={value}
                aria-label="first name"
                type="text"
                label="First name"
                error={errors.firstName && errors.firstName.message}
                placeholder="First name"
                className="fs-exclude"
                autoComplete="given-name"
              />
            )}
            rules={formValidations.firstName}
          />
        </InputGroup>
        <InputGroup>
          <Controller
            name="lastName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                onBlur={trackFormUpdated('lastName')}
                value={value}
                aria-label="last name"
                type="text"
                label="Last name"
                error={errors.lastName && errors.lastName.message}
                placeholder="Last name"
                className="fs-exclude"
                autoComplete="family-name"
              />
            )}
            rules={formValidations.lastName}
          />
        </InputGroup>
        <InputGroup>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onBlur={trackFormUpdated('email')}
                onChange={onChange}
                value={value}
                aria-label="email"
                type="email"
                label="Email"
                autoComplete="email"
                error={errors.email && errors.email.message}
                placeholder="youremail@mail.com"
                className="fs-exclude"
              />
            )}
            rules={formValidations.email}
          />
        </InputGroup>
        <InputGroup style={{ marginTop: '1rem' }}>
          <Controller
            name="country"
            control={control}
            rules={formValidations.country}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Country"
                value={value}
                options={countryOptions}
                onChange={(newValue) => {
                  onChange(newValue);
                  trackFormUpdated('country');
                }}
              />
            )}
          />
        </InputGroup>
        <MarketingCheck>
          <Controller
            name="marketingConsent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={MARKETING_OPT_IN_COPY}
                onChange={(newChecked) => {
                  onChange(newChecked);
                  trackFormUpdated('marketingConsent')();
                }}
                checked={Boolean(value)}
              />
            )}
          />
        </MarketingCheck>
        <Button type="submit" variant="primary">
          Let me know
        </Button>
      </Stack>
    </Form>
  );
};

export default MobileWaitingListForm;
