import { LetterBoxPlace, PlaceSuggestion } from '../types';

/**
 * Builds a user-friendly PlaceSuggestion from a LetterBoxPlace, using the passed index as id.
 *
 * @param place - The LetterBoxPlace object containing address details.
 * @param indexOf - The index to be used as the id for the PlaceSuggestion.
 * @returns A PlaceSuggestion object with a formatted text string and id.
 *
 * @example
 * // Full address
 * buildPlaceSuggestion({addressLine1: '123 Main St', city: 'New York', stateOrRegion: 'NY', postalCode: '10001'}, 0)
 * // => { text: '123 Main St, New York, 10001', id: 0 }
 *
 * @example
 * // Address without city, including state
 * buildPlaceSuggestion({addressLine1: '456 Oak Ave', city: '', stateOrRegion: 'CA', postalCode: '90210'}, 1)
 * // => { text: '456 Oak Ave, CA, 90210', id: 1 }
 */
export const buildPlaceSuggestion = (place: LetterBoxPlace, indexOf: number): PlaceSuggestion => {
  const { addressLine1, city, stateOrRegion, postalCode } = place;

  const builder = [addressLine1, city];

  // Add state or region if one of the lower level address parts are missing.
  if (!(addressLine1 && city) && stateOrRegion) builder.push(stateOrRegion);

  builder.push(postalCode);

  return { text: builder.filter(Boolean).join(', '), id: indexOf };
};
