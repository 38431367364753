import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import { useIsMobile } from 'utils/useIsMobile';
import ROWWaitingList from 'components/Signup/ROWWaitingList';
import MobileWaitingListSuccess from 'components/Mobile/MobileWaitingListSuccess';
import Homepage from './Homepage';
import CompanyDetails from './CompanyDetails';
import MobileWaitingList from '../Mobile/MobileWaitingList';
import HomepageAside from './HomepageAside';

const SignupHomepage = () => {
  return (
    <Layout showLogin isEvenSidebar asideContent={<HomepageAside />}>
      <Homepage />
    </Layout>
  );
};

const CompanyDetailsWithLayout = () => (
  <Layout showLogo>
    <CompanyDetails />
  </Layout>
);

const ROWWaitingListWithLayout = () => {
  return (
    <Layout showLogo preventAnimations>
      <ROWWaitingList />
    </Layout>
  );
};

export const Signup = () => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) navigate('/signup/mobile', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <Routes>
      <Route index element={<SignupHomepage />} />
      <Route path="complete" element={<CompanyDetailsWithLayout />} />
      <Route path="coming-soon" element={<ROWWaitingListWithLayout />} />
      <Route path="mobile" element={<MobileWaitingList />} />
      <Route path="mobile/success" element={<MobileWaitingListSuccess />} />
    </Routes>
  );
};
