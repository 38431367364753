import { MobileFormData } from 'components/Mobile/MobileWaitingListForm';
import { trackFormInteraction } from 'utils/adobeAnalytics/trackFormInteraction';
import { addUTMData, identifySegmentEvent, trackSegmentEvent } from './utils/helpers';
import { Event, WaitingList } from './types';

export const mobileWaitingListSignupTracking = (formData: MobileFormData) => {
  const { firstName, lastName, email, marketingConsent, country } = formData;

  const baseData = {
    firstName,
    lastName,
    email,
    country,
    marketingConsent,
    marketingConsentDate: marketingConsent ? new Date().toISOString() : '',
    signup_waiting_list: WaitingList.MOBILE,
    company: {
      name: email,
    },
  };
  const dataWithUTM = addUTMData(baseData);

  identifySegmentEvent(dataWithUTM);
  trackSegmentEvent(Event.MOBILE_WAITING_LIST_SUBSCRIPTION, {
    firstName,
    lastName,
    email,
    country,
  });

  trackFormInteraction({
    formName: 'mobile-lead-form',
    interactionType: 'submit',
  });
};
