import { useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '@veeqo/ui';
import VeeqoLogo from 'components/common/VeeqoLogo';
import TitleGroup from 'components/common/TitleGroup';
import { Footer } from 'components/layout/Footer';
import TermsConditions from 'components/Terms/TermsConditions';
import { useIsMobile } from 'utils/useIsMobile';
import { useNavigate } from 'react-router-dom';
import MobileWaitingForm from './MobileWaitingListForm';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  flex-grow: 1;
  max-width: 100%;
  margin-top: ${theme.sizes[20]};
  padding: 0 ${theme.sizes[8]};
`;

const MobileWaitingList = () => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) navigate('/signup', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <Wrapper>
      <Container>
        <VeeqoLogo tag="Almost ready" direction="vertical" />
        <TitleGroup
          isMobile
          title="Get the best Veeqo experience on desktop!"
          subtitle="Enter your info here and we’ll send you a link to continue on desktop!"
        />
        <MobileWaitingForm
          onMobileSuccess={() => navigate('/signup/mobile/success', { replace: true })}
        />
        <TermsConditions waitingList />
      </Container>
      <Footer />
    </Wrapper>
  );
};

export default MobileWaitingList;
