import { SearchMode, getSearchMode } from 'hooks/usePlaceLookup/constants';
import { getTextAndPostcodeSearchBody, getTextSearchBody } from './utils';
import { VQ_LETTERBOX_URL } from './constants';
import { LetterboxResponse } from './types';

export const fetchAddressSuggestions = async (
  query: string,
  countryCode: string = 'US',
): Promise<LetterboxResponse> => {
  const searchBody =
    getSearchMode(countryCode) === SearchMode.SEARCH_FOR_UK_POSTCODE
      ? getTextAndPostcodeSearchBody(query, countryCode)
      : getTextSearchBody(query, countryCode);

  const response = await fetch(VQ_LETTERBOX_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(searchBody),
  });

  if (!response.ok) throw new Error('Failed to fetch address suggestions');

  return response.json();
};

// /**
//  * A mock function to return fixed addresses after a 500ms delay. With fitlering based on query.
//  */
// export const fetchAddressSuggestions = async (
//   query: string,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   countryCode: string = 'US',
// ): Promise<LetterboxResponse> => {
//   // eslint-disable-next-line no-promise-executor-return
//   await new Promise((resolve) => setTimeout(resolve, 500));

//   // Mock data based on query
//   const mockSuggestions = [
//     {
//       addressLine1: '123 Test Street',
//       addressLine2: 'Floor 2',
//       addressLine3: 'Building A',
//       city: 'London',
//       countryCode: 'GB',
//       postalCode: 'SW1A 1AA',
//       stateOrRegion: 'Greater London',
//       districtOrCounty: 'Westminster',
//       name: 'Tech Company Ltd',
//       phoneNumber: '+44 20 7123 4567',
//       email: 'contact@techcompany.com',
//     },
//     {
//       addressLine1: '456 Test Road',
//       addressLine2: 'Suite 101',
//       addressLine3: 'Enterprise Park',
//       city: 'Manchester',
//       countryCode: 'GB',
//       postalCode: 'M1 1BB',
//       stateOrRegion: 'Greater Manchester',
//       districtOrCounty: 'City Centre',
//       name: 'Digital Solutions Ltd',
//       phoneNumber: '+44 161 876 5432',
//       email: 'info@digitalsolutions.co.uk',
//     },
//     {
//       addressLine1: '789 Test Avenue',
//       addressLine2: 'Unit 3',
//       addressLine3: 'Business Complex',
//       city: 'Birmingham',
//       countryCode: 'GB',
//       postalCode: 'B1 1CC',
//       stateOrRegion: 'West Midlands',
//       districtOrCounty: 'Birmingham City',
//       name: 'Creative Studios Ltd',
//       phoneNumber: '+44 121 345 6789',
//       email: 'hello@creativestudios.com',
//     },
//     {
//       addressLine1: '321 Example Way',
//       addressLine2: 'Level 4',
//       addressLine3: 'Tech Hub',
//       city: 'Bristol',
//       countryCode: 'GB',
//       postalCode: 'BS1 4DD',
//       stateOrRegion: 'Bristol',
//       districtOrCounty: 'City of Bristol',
//       name: 'Future Systems Ltd',
//       phoneNumber: '+44 117 987 6543',
//       email: 'contact@futuresystems.co.uk',
//     },
//     {
//       addressLine1: '555 Example Drive',
//       addressLine2: 'Office 201',
//       addressLine3: 'The Pinnacle',
//       city: 'Leeds',
//       countryCode: 'GB',
//       postalCode: 'LS1 5EE',
//       stateOrRegion: 'West Yorkshire',
//       districtOrCounty: 'Leeds City',
//       name: 'Northern Digital Ltd',
//       phoneNumber: '+44 113 234 5678',
//       email: 'info@northerndigital.co.uk',
//     },
//   ].filter((address) =>
//     Object.values(address).some((value) => value.toLowerCase().includes(query.toLowerCase())),
//   );
//   console.log('suggestions', mockSuggestions);

//   return {
//     suggestions: mockSuggestions,
//   };
// };
