import { useState } from 'react';
import styled from 'styled-components';
import { Button, theme } from '@veeqo/ui';
import VeeqoLogo from 'components/common/VeeqoLogo';
import TitleGroup from 'components/common/TitleGroup';
import Layout from 'components/layout/Layout';
import WaitingListForm from './WaitingListForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 420px;
  margin-top: ${theme.sizes[20]};
`;

const SuccessButton = styled(Button)`
  width: 100%;
`;

const WaitingList = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const copy = {
    form: {
      title: 'Get access soon!',
      subtitle: "Pass us your email, and we'll let you know when Veeqo is ready for access",
    },
    success: {
      title: "We'll let you know!",
      subtitle: `We’ve added ${email} to the waitlist. We’ll let you know when you can sign up and start shipping with Veeqo!`,
    },
  };

  const handleSetSuccess = (emailAddress: string) => {
    setEmail(emailAddress);
    setIsSuccess(true);
  };

  const handleClickSuccess = () => {
    window.location.href = 'https://veeqo.com';
  };

  return (
    <Layout>
      <Wrapper>
        <Container>
          <VeeqoLogo tag="Ready soon" direction="horizontal" />
          <TitleGroup
            isMobile
            title={isSuccess ? copy.success.title : copy.form.title}
            subtitle={isSuccess ? copy.success.subtitle : copy.form.subtitle}
          />
          {isSuccess ? (
            <SuccessButton variant="primary" onClick={handleClickSuccess} role="link">
              Back to Veeqo.com
            </SuccessButton>
          ) : (
            <WaitingListForm setSuccess={handleSetSuccess} />
          )}
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default WaitingList;
