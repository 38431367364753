import React from 'react';
import styled from 'styled-components';
import { Text, theme } from '@veeqo/ui';
import links from 'utils/externalLinks';
import { handleTrackLinkClick } from 'utils/adobeAnalytics/trackLinkInteraction';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.sizes[20]};
`;

const List = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  padding: 0 ${theme.sizes[5]};
  a {
    text-decoration: none;
  }
`;

export const Footer = () => (
  <Container>
    <List>
      <ListItem>
        <a
          href={links.veeqoHelp}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleTrackLinkClick('footer')}
        >
          <Text variant="body">Help</Text>
        </a>
      </ListItem>
      <ListItem>
        <a
          href={links.veeqoPrivacy}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleTrackLinkClick('footer')}
        >
          <Text variant="body">Privacy</Text>
        </a>
      </ListItem>
      <ListItem>
        <a
          href={links.veeqoTerms}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleTrackLinkClick('footer')}
        >
          <Text variant="body">Terms</Text>
        </a>
      </ListItem>
    </List>
  </Container>
);
