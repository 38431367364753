import { AdobeEvents } from './constants';
import { OptionalUser, Account, LinkInteractionData } from './types';
import {
  getAccountObject,
  getLinkObject,
  getSocialData,
  getUserObject,
} from './utils/adobeObjectUtils';
import { checkIsSatelliteDefined } from './utils/checkIsSatelliteDefined';
import { reportSatelliteMissing } from './utils/utils';

type TrackLinkInteractionArgs = {
  location: string;
  navigationPath?: string;
  url: string;
  text: string;
  isCta?: boolean;
  isSocial?: boolean;
  socialData?: {
    name: string;
    interactionType: string;
  };
  user?: OptionalUser;
  account?: Omit<Partial<Account>, 'membershipType'>;
  shouldSendToSentry?: boolean;
};

export const trackLinkInteraction = ({
  location,
  navigationPath,
  url,
  text,
  isCta = false,
  isSocial = false,
  socialData,
  user,
  account,
  shouldSendToSentry,
}: TrackLinkInteractionArgs) => {
  if (!checkIsSatelliteDefined()) return reportSatelliteMissing({ shouldSendToSentry });

  const eventData: LinkInteractionData = {
    link: getLinkObject({
      ctaLink: isCta,
      socialLink: isSocial,
      text,
      url,
      navigationPath,
      location,
    }),
    social: getSocialData(socialData),
    user: getUserObject(user),
    account: getAccountObject(account),
  };

  _satellite.track(AdobeEvents.LINK_INTERACTION, eventData);
};

export const handleTrackLinkClick =
  (location: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    const link = e.currentTarget;
    trackLinkInteraction({
      location,
      url: link.href,
      text: link.textContent || link.getAttribute('aria-label') || '',
    });
  };
