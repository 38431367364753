import styled from 'styled-components';
import { Button } from '@veeqo/ui';
import { AmazonIcon } from './AmazonIcon';

interface AmazonButtonProps {
  text: string;
  style?: any;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const AmazonButtonComponent = styled(Button)`
  background-image: linear-gradient(rgb(247, 223, 165), rgb(240, 193, 75));
  border-top-color: rgb(168, 135, 52);
  border-right-color: rgb(156, 126, 49);
  border-bottom-color: rgb(132, 106, 41);
  border-left-color: rgb(156, 126, 49);
  color: #111;

  &:hover {
    background-image: linear-gradient(rgb(245, 215, 142), rgb(238, 185, 51));
    border-top-color: rgb(168, 135, 52);
    border-right-color: rgb(156, 126, 49);
    border-bottom-color: rgb(132, 106, 41);
    border-left-color: rgb(156, 126, 49);
  }

  &:active {
    border-color: rgb(231, 118, 0);
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
  }

  &:focus-visible {
    border-color: rgb(231, 118, 0);
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
  }
`;

export const AmazonButton = ({
  text,
  style,
  loading = false,
  disabled = false,
  onClick,
}: AmazonButtonProps) => (
  <AmazonButtonComponent
    iconSlot={<AmazonIcon />}
    style={{ ...style }}
    onClick={onClick}
    loading={loading}
    disabled={disabled}
  >
    {text}
  </AmazonButtonComponent>
);
