import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '../trackPageView';
import { retry } from '../utils/retryUtil';
import { FURTHER_ADOBE_SETUP_WAIT_TIME } from '../constants';
import { checkIsSatelliteDefined } from '../utils/checkIsSatelliteDefined';

export const usePageTracking = () => {
  const location = useLocation();

  const [isSatelliteAvailable, setIsSatelliteAvailable] = useState<boolean | null>(null);

  useEffect(() => {
    retry(checkIsSatelliteDefined).then((result) => {
      // eslint-disable-next-line no-console
      console.debug(`AA Load Result: ${result ? 'success' : 'failure'}`);

      if (result) {
        setTimeout(() => setIsSatelliteAvailable(true), FURTHER_ADOBE_SETUP_WAIT_TIME);
      } else {
        setIsSatelliteAvailable(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isSatelliteAvailable) return;
    trackPageView();
  }, [location, isSatelliteAvailable]);
};
