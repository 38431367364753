import { AdobeEvents } from './constants';
import {
  Account,
  FormInteractionData,
  AATrackedForms,
  FormInteractionTypes,
  OptionalUser,
} from './types';
import { getAccountObject, getUserObject } from './utils/adobeObjectUtils';
import { checkIsSatelliteDefined } from './utils/checkIsSatelliteDefined';
import { reportSatelliteMissing } from './utils/utils';

type TrackFormInteractionArgs = {
  formName: AATrackedForms;
  interactionType: FormInteractionTypes;
  formField?: string;
  user?: OptionalUser;
  account?: Omit<Partial<Account>, 'membershipType'>;
  shouldSendToSentry?: boolean;
};

export const trackFormInteraction = ({
  formName,
  interactionType,
  formField = '', // Only provide for onChange.
  user,
  account,
  shouldSendToSentry,
}: TrackFormInteractionArgs) => {
  if (!checkIsSatelliteDefined()) return reportSatelliteMissing({ shouldSendToSentry });

  const eventData: FormInteractionData = {
    form: {
      action: interactionType,
      name: formName,
      fieldQuestion: formField,
      demoRequest: false,
    },
    user: getUserObject(user),
    account: getAccountObject(account),
  };

  _satellite.track(AdobeEvents.FORM_INTERACTION, eventData);
};
