import debounce from 'lodash.debounce';
import { useState, useCallback, useMemo, useRef } from 'react';
import { captureException } from '@sentry/react';
import { fetchAddressSuggestions } from './utils/addressSuggestions';
import { Country } from './constants';
import { LetterBoxPlace, OnSelectArgs } from './types';
import { buildPlaceSuggestion } from './utils/utils';

export const usePlaceLookup = () => {
  const [data, setData] = useState<LetterBoxPlace[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const suggestions = useMemo(() => data.map(buildPlaceSuggestion), [data]);

  const fetchSuggestions = useCallback(async (query: string, countryCode: string = Country.US) => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await fetchAddressSuggestions(query, countryCode);
      setData(result.suggestions || []);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setData([]);
      setError(errorMessage);
      captureException({ error: err, sentryTags: { countryCode, errorMessage } });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debounceOnChange = useRef(debounce(fetchSuggestions, 320)).current;

  const onSelect = useCallback(
    ({ suggestion, onSuccess }: OnSelectArgs) => {
      if (data.length > (suggestion.id as number)) {
        onSuccess(data[suggestion.id as number]);
      }
    },
    [data],
  );

  const resetStates = useCallback(() => {
    setData([]);
    setIsLoading(false);
    setError(null);
  }, []);

  return {
    suggestions,
    loading: isLoading,
    error,
    onChange: debounceOnChange,
    onSelect,
    resetStates,
  };
};
