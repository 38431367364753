import {
  Account,
  ArticleObject,
  CustomerStoryObject,
  ErrorObject,
  GuideObject,
  LinkObject,
  OptionalUser,
  PageObject,
  SocialData,
  User,
} from '../types';
import { getUTMParameters } from './utils';

export const getPageObject = (): PageObject => ({
  url: window.location.href,
  domain: window.location.hostname,
  path: window.location.pathname,
  queryString: window.location.search,
  title: document.title,
  referrer: document.referrer,
  section: '',
  // Always false flags - required to pass.
  errorPage: false,
  articlePage: false,
  guidePage: false,
  customerStoryPage: false,
});

export const getLinkObject = (linkData: Partial<LinkObject>): LinkObject => ({
  location: linkData.location || '',
  navigationPath: linkData.navigationPath || '',
  url: linkData.url || '',
  text: linkData.text || '',
  ctaLink: linkData.ctaLink || false,
  socialLink: linkData.socialLink || false,
});

export const getUserObject = ({ id }: OptionalUser = {}): User => ({
  id: id || '',
  membershipType: '',
  utmParameters: getUTMParameters(),
});

export const getAccountObject = ({
  companyID,
  companyName,
  membershipType,
}: Partial<Account> = {}): Account => ({
  companyID: companyID || '',
  companyName: companyName || '',
  membershipType: membershipType || '',
});

export const getSocialData = (socialData?: Partial<SocialData>): SocialData => ({
  name: socialData?.name || '',
  interactionType: socialData?.interactionType || '',
});

export const getErrorObject = (errorPageData?: Partial<ErrorObject>): ErrorObject => ({
  type: errorPageData?.type || '',
  text: errorPageData?.text || [],
});

export const getArticleObject = (articleData?: Partial<ArticleObject>): ArticleObject => ({
  category: articleData?.category || '',
  name: articleData?.name || '',
  publishDate: articleData?.publishDate || '',
  writtenBy: articleData?.writtenBy || '',
  readTime: articleData?.readTime || '',
});

export const getGuideObject = (guideObject?: Partial<GuideObject>): GuideObject => ({
  name: guideObject?.name || '',
});

export const getCustomerStoryObject = (
  customerStoryObject?: Partial<CustomerStoryObject>,
): CustomerStoryObject => ({
  name: customerStoryObject?.name || '',
});
