import { useRef } from 'react';
import { trackFormInteraction } from '../trackFormInteraction';
import { AATrackedForms } from '../types';

/**
 * A hook to track form changes.
 *
 * Warning: trackFormUpdated should be used with care, only passing in fixed strings for the field name.
 *
 * @param formName The name of the form to track.
 * @returns trackFormUpdated: (fieldName: string) => () => void
 */
export const useTrackForm = (formName: AATrackedForms) => {
  const hasFormChanged = useRef(false); // Ref so debounce can access state.

  /**
   * Used to track form interactions, a HOF to be used directly with an onBlur event.
   *
   * WARNING: Only ever pass in a fixed string, the fieldName.
   */
  const trackFormUpdated = (fieldName: Readonly<string>) => () => {
    if (!hasFormChanged.current) {
      hasFormChanged.current = true;
      trackFormInteraction({
        formName,
        interactionType: 'start',
      });
    }

    trackFormInteraction({
      formName,
      interactionType: 'updated',
      formField: fieldName,
    });
  };

  return {
    trackFormUpdated,
  };
};
